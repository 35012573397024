<template>
  <div class="nav-promo-media-card" v-if="getContent">
    <LinkTag
      interstitial
      @click.native="() => handleClick()"
      :link="getContent.linkUrl"
    >
      <template
        v-if="appearance === 'default' && GET_IS_BELOW_BREAKPOINT('md')"
      >
        <div class="nav-promo-media-card__wrapper flex--row-center-stretch">
          <div class="nav-promo-media-card__image-left">
            <div class="nav-promo-media-card__image-sizer"></div>
            <bleach-image
              v-if="getImage"
              class="nav-promo-media-card__image"
              :src="getImage.original_secure_url"
              :sizes="{
                sm: [250, 'ar_1'],
                md: [280, 'ar_1']
              }"
              :default-size="[280, 'ar_1']"
              :alt="getImage.metadata.alt || getContent.altText"
              imageQuality="60"
            />
          </div>
          <div class="nav-promo-media-card__text-right flex--col-center-start">
            <bleach-image
              v-if="getTexture"
              class="nav-promo-media-card__image"
              :src="getImage.original_secure_url"
              :sizes="{
                sm: [250, 'ar_1'],
                md: [280, 'ar_1']
              }"
              :default-size="[280, 'ar_1']"
              :alt="getTexture.metadata.alt || getContent.title"
              imageQuality="60"
            />
            <div
              class="text-commerce-heading-2 text--clr-white text--uppercase mb-8"
            >
              {{ title }}
            </div>
            <div class="text-body-small text--clr-white mb-16">
              {{ getContent.title }}
            </div>
            <div
              class="text-commerce-button-primary text--clr-white text--uppercase"
            >
              {{ getContent.cta }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="nav-promo-media-card__image-sizer mb-16">
          <bleach-image
            v-if="getImage"
            class="nav-promo-media-card__image"
            :src="getImage.original_secure_url"
            :sizes="{
              sm: [250, 'ar_1'],
              md: [280, 'ar_1']
            }"
            :default-size="[280, 'ar_1']"
            :alt="getImage.metadata.alt || getContent.altText"
            imageQuality="60"
          />
        </div>
        <div class="nav-promo-media-card__text">
          <div class="text-commerce-heading-3 text--clr-black mb-24">
            {{ getContent.title }}
          </div>
          <div
            class="text-commerce-button-primary text--clr-black text--uppercase"
          >
            {{ getContent.cta }}
          </div>
        </div>
      </template>
    </LinkTag>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import GTMAPI from "@/integration/GTMAPI"
import ContentfulMixin from "@/contentfulComponents/ContentfulMixin"

export default {
  mixins: [ContentfulMixin],

  props: {
    appearance: String,
    title: String
  },

  methods: {
    handleClick() {
      GTMAPI.trackEvent("navigation.promo.clicked", {
        item_title: this.getContent.title
      })
    }
  },

  computed: {
    ...mapGetters("breakpoints", ["GET_IS_BELOW_BREAKPOINT"]),

    getImage() {
      return this.getContent.image && this.getContent.image[0]
    },

    getTexture() {
      return this.getContent.image && this.getContent.image[1]
    }
  }
}
</script>

<style lang="scss">
.nav-promo-media-card {
  position: relative;
  width: 100%;
  height: auto;

  &__wrapper {
    position: relative;
    align-items: stretch;
  }

  &__image-left {
    position: relative;
    flex: 0 0 33.33%;
    width: 33.33%;
    height: auto;
    overflow: hidden;
  }

  &__text-right {
    position: relative;
    flex: 0 0 66.66%;
    width: 66.66%;
    overflow: hidden;
    height: auto;
    padding: 24px;
    background-color: getcolour(bleach_black);
  }

  &__image-sizer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>
